import MarqueeStyles from "@/components/price-ticker/price-ticker.module.scss";
import AmdIcon from "@spheron/ui-library/dist/assets/gpu-amd.svg";
import NvidiaIcon from "@spheron/ui-library/dist/assets/gpu-nvidia.svg";

interface IProps {
  gpuPrices:
    | {
        vendor: string;
        name: string;
        averagePrice: number;
        availableNum: number;
      }[]
    | null;
}

const getGpuIcon = (gpu: string) => {
  switch (gpu.toLowerCase()) {
    case "amd":
      return <AmdIcon className="w-5 h-5" />;
    case "nvidia":
    default:
      return <NvidiaIcon className="w-5 h-5" />;
  }
};

const PriceTicker = ({ gpuPrices }: IProps) => {
  const marqueeText = (count: number) => {
    if (gpuPrices)
      return [...gpuPrices, ...gpuPrices]
        .filter((gpu) => gpu.availableNum > 0)
        .map((gpu, i) => (
          <div
            key={`${gpu.name}-${count}-${i}`}
            className="flex flex-row gap-x-2 items-center justify-center text-center py-0.5"
          >
            <div className="flex flex-row gap-x-1 justify-center items-center">
              <span className="text-3 font-500 leading-3 flex flex-row gap-1 items-center">
                {getGpuIcon(gpu.vendor)} {gpu.name}
              </span>
              <div
                className="w-min whitespace-nowrap rounded font-semibold p-1 text-[10px] leading-3
                  bg-dark-feedback-info-bg text-dark-feedback-info-text dark:bg-feedback-info-bg dark:text-feedback-info-text"
              >
                x{gpu.availableNum}
              </div>
            </div>
            <span className="text-3 font-700 leading-3">
              ${gpu.averagePrice?.toFixed(2)}/hr
            </span>
          </div>
        ));
    return "";
  };

  return (
    <>
      {gpuPrices && (
        <div
          className="bg-feedback-info-bg dark:bg-dark-feedback-info-bg 
          text-feedback-info-text dark:text-dark-feedback-info-text py-1"
        >
          <div className={MarqueeStyles.marquee__text}>
            <div className={MarqueeStyles.marquee__text__track}>
              {marqueeText(1)}
              {marqueeText(2)}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PriceTicker;
